.active_filters {
    padding: 15px;
    &-hide {
        display: none;
    }
    .active-filter-title {
        font-size: 1rem;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        .filter-block {
            font-size: 0.9rem;
            .js-search-link {
                color: $secondary;
            }
        }
    }
}
#search_filters {
    padding: 15px 0;
    position: relative;
    .filter-by {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .title {
        position: relative;
        // font-size: 1.1rem;
        text-transform: uppercase;
        display: block;
        height: 30px;
        line-height: 30px;
        color: $secondary-darker;
        border-bottom: solid 1px $gray-300;
        margin-bottom: 15px;
        .facet-title {
            font-size: 0.9rem;
        }
        p {
            margin: 0;
            line-height: 30px;
        }
        .collapse-icons {
            position: absolute;
            right: 0;
            top: 0;
            color: $secondary-darker;
        }
        &[aria-expanded="true"] {
            .add {
                display: none;
            }
        }
        &[aria-expanded="false"] {
            .remove {
                display: none;
            }
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 20px;
        li {
            input {
                box-shadow: none;
            }
            a {
                color: $secondary;
                text-decoration: none;
                font-size: 0.9rem;
                .magnitude {
                    color: $secondary-light;
                    font-size: 0.85rem;
                }
            }
        }
    }
    .mobile-icons-facet {
        position: absolute;
        right: 0;
        top: -7px;
        // bottom: 0;
        color: #515151;
        padding: 5px 5px;
        &[aria-expanded="false"] {
            .minus {
                display: none;
            }
        }
        &[aria-expanded="true"] {
            .add {
                display: none;
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding: 0;
        .filter-by {
            line-height: 1;
        }
    }
}