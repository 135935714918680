.user-info {
    margin-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(lg) {
        margin-right: 5px;
        .user-link {
            display: none;
        }
    }
    .user-link {
        text-align: center;
    }
    .welcome {
        color: $primary;
        font-size: 12px;
    }
    a {
        text-decoration: none;
        font-size: 12px;
    }
    a:hover {
        color: $primary;
    }
    .sign-in {
        text-align: center;
        span {
            margin-top: 4.2px;
        }
    }
}

#login-page {
    h5, p {
        color: #515151;
    }
    h2 {
        color: #333;
    }

    .forgot-password {
        a {
            background-color: #575757;
            color: $white;
        }
        h4 {
            color: #333;
        }
    }
}

.page-footer {
    .fgtpassword {
        a {
            background-color: #575757;
            color: $white;
        }
    }
}