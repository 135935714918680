#history, #order-detail {
    table {
        width: 100%;
        thead {
            th {
                background-color: $secondary;
                color: $white;
            }
        }
        tfoot {
            tr {
                td {
                    border: 0;
                }
            }
        }
    }

    .history-lines {
        .history-line {
            border-bottom: solid 1px $secondary;
            &:last-of-type {
                border-bottom: 0;
            }
        }
    }

    .order {
        &.card {
            margin-bottom: 20px;
            padding: 15px;
        }
    }
    .card {
        padding: 15px;
        margin-bottom: 20px;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }
    .messages {
        margin-bottom: 20px;
        .message {
            margin: 0;
            border-bottom: solid 1px $secondary;
            padding: 10px 0;
        }
    }
    .order-items {
        .order-item {
            border-bottom: solid 1px $secondary;
            padding-bottom: 15px;
            margin-bottom: 15px;
            &:last-of-type {
                border-bottom: 0;
                padding-bottom: 0;
                margin-bottom: 0;
            }
            .name {
                font-weight: bold;
                margin-bottom: 5px;
            }
            .prices {
                .total {
                    text-align: right;
                }
            }

        }
    }
    .order-totals {
        .order-total {
            .prices {
                text-align: right;
            }
        }
    }
}
