#_desktop_top_menu {
  .top-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 15px 0;
  }
  ul {
    padding: 0;
      #category-2 {
          border-left: 1px solid #cccccc;
      }
      li {
          border-top: 1px solid #cccccc;
          border-right: 1px solid #cccccc;
          border-bottom: 1px solid #cccccc;
          text-align: center;
          padding: 5px 0 5px 0;
          width: 15%;
          a {
            color: #484848;
            font-weight: 600;
            &:hover {
              background-color: transparent;
              color: #69748d;
            }
            &:focus {
              background-color: transparent;
            }
          }
          @include media-breakpoint-down(lg) {
            font-size: 0.8rem;
            font-weight: 400;
          }
      }
  }
}


