#custom-text {
  text-align: center;
  padding: 25px 0;
  p {
    color: $primary;
    font-size: 1.3rem;
    font-weight: 600;
  }
  .ct-ul {
    padding: 0;
    .ct-li {
      display: inline;
      padding: 25px;
      color: #515151;
      i {
        margin-right: 10px;
        color: $primary;
        -webkit-text-stroke: 2px;
      }
    }
    @include media-breakpoint-down(lg) {
      .ct-li {  
        display: flex;
        justify-content: center;
        padding: 0;
      }
    }  
  }
}