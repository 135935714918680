#map {
    margin-top: -35px;
    .sitemap {
        .row {
            margin-top: 12px;
            .sitemap-title {
                color: #515151;
                margin-bottom: 15px;
            }
            .columns {
                display: flex;
                justify-content: center;
                .cat {
                    margin: 0 15px 15px 0; /* Add margin between .cat elements */
                    display: inline-block;
                    border: 1px solid $dark-gray-light; /* Or any border style you prefer */
                    position: relative;
                    padding: 15px;
                    h4 {
                        color: #515151;
                    }
                    
                    a {
                        text-decoration: none;
                        color: #777;
                        font-size: 0.9rem;
                    }
                }
                @include media-breakpoint-down(xl) {
                    .cat {
                        width: 42%;
                    }
                }
                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    .cat {
                        width: 100%;
                    }
                }
            }
        }
    }
}
