#topbrands {
    background: $gray-200;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 50px;
    .item {
        margin-bottom: 30px;
        a {
            img {
                width: 100%;
                height: auto;
                filter: grayscale(0);
                transition: $transition-base;
            }
            &:hover {
                img {
                    filter: grayscale(1);
                    transition: $transition-quick;
                }
            }
        }
    }
}

#footer {
    .brands {
        .items {
            column-count: 2;
        }
    }
}