.checkout-wrapper {
    margin-top: 15px;
    margin-bottom: 30px;
    .checkout-step {
        background: $dark-gray-lighter;
        border:solid 1px $dark-gray-light;
        margin-bottom: 30px;
        .title {
            padding: 15px;
            background: $dark-gray;
            display: flex;
            justify-content: space-between;
            h1 {
                font-size: 1.5rem;
                color: $white;
                margin: 0;
            }
            .edit {
                color:  $dark-gray-lighter;
                cursor: pointer;
                border: solid 1px $dark-gray-lighter;
            }
        }
        .content {
            display: none;
        }
        &.-current {
            .title {
                .edit {
                    display: none;
                }
            }
            .content {
                display: block;
                padding: 30px 5%;
                #customer-form {
                    max-width: 100%;
                    border-top: 0;
                }
                #login-form {
                    .card {
                        border-top: 0;
                    }
                }
                .nav {
                    margin: 15px 5% -1px 5%;
                    align-items:stretch;
                    .nav-item {
                        background: $white;
                        border: solid 1px $dark-gray-light;
                    }
                    .nav-link {
                        background: $dark-gray-light;
                        color: $dark-gray;
                        display: block;
                        height: 100%;
                        &.active {
                            background: $white;
                            border-bottom-color: $white;
                        }
                    }
                }
                .tab-content {
                    padding: 0 5% 30px 5%;
                }
            }
        }
        &.-unreachable {
            .title {
                background: $dark-gray-medium;
                h1 {
                    color:$white;
                }
            }
        }
        .forgot-password {
            border: solid 1px $dark-gray-light;
            margin-top: 30px;
            padding: 15px;
            h4 {
                color: #515151;
            }
            .btn {
                background-color: #515151;
                color: $white;
            }
        }
        &#checkout-personal-information-step {
            .content {
                padding: 0;
            }
            .logged {
                padding: 30px 5%;
            }
        }
        &#checkout-addresses-step {
            #delivery-addresses, #invoice-addresses {
                .col-6 {
                    margin-bottom: 30px;
                }
                .address-item {
                    label {
                        font-weight: normal;
                        display: flex;
                        .custom-radio {
                            margin-right: 15px;
                        }
                    }
                    address {
                        margin: 0;
                    }
                    border: solid 1px $dark-gray-light;
                    background: $dark-gray-lighter;
                    transition: background-color 0.2s ease;
                    height: 100%;
                    position: relative;
                    color: $dark-gray-medium;
                    cursor: pointer;
                    h4 {
                        color: $dark-gray;
                        transition: $transition-quick;
                    }
                    &.selected {
                        background:$white;
                        // border: solid 3px $primary;
                        transition: background-color 0.2s ease;
                        color: $dark-gray;
                        h4 {
                            color: #515151;
                            transition: $transition-quick;
                        }
                        header {
                            padding: 13px;
                        }
                    }
                    header {
                        padding: 15px;
                    }
                }
                .address-footer {
                    margin-left: 43px;
                    margin-bottom: 15px;
                    a {
                        color: $medium-gray;
                        text-decoration: none;
                        border: solid 1px $medium-gray;
                        padding: 2px 5px;
                        margin-right: 15px;
                        font-size: 0.8rem;
                    }
                }
            }
            .add-address {
                width: calc(50% - 12px);
                margin-bottom: 15px;
                a {
                    border: solid 1px $dark-gray-light;
                    background: $white;
                    display: block;
                    padding: 15px;
                    color: $dark-gray;
                    text-decoration: none;
                    display: flex;
                    i {
                      margin-right: 15px;
                      font-size: 2rem;
                      margin-top: 5px;
                    }
                    h4 {
                        margin-bottom: 0;
                    }
                }
            }
            .different-invoice-address {
                border: solid 1px $dark-gray-light;
                background: $white;
                display: block;
                padding: 15px;
                color: $dark-gray;
                text-decoration: none;
                font-weight: 700;
            }
        }
        &#checkout-delivery-step {
            .delivery-options {
                border: solid 1px $dark-gray-light;
                background: $white;
                padding: 0;
                margin: 0;
                label {
                    font-weight: normal;
                }
                .delivery-option {
                    margin: 0 15px;
                    padding: 30px 0;
                    border-bottom: dashed 1px $dark-gray-medium;
                    .carrier-name {
                        font-size: 1.2rem;
                        color: #333;
                    }
                    .carrier-delay, .carrier-price, small {
                        color: #333;
                    }
                    &:first-of-type {
                        margin-top: 15px;
                    }
                    &:last-of-type {
                        border-bottom: 0;
                        margin-bottom: 15px;
                    }
                    small {
                        font-size: 0.8rem;
                        margin-top: 5px;
                        display: block;
                    }
                }

            }
            .order-options {
                margin: 30px 0;
                #delivery_message {
                    width: 100%;
                }
            }
        }
        &#checkout-payment-step {
            .payment-options {
                border: solid 1px $dark-gray-light;
                background: $white;
                padding: 0;
                margin: 0;
                list-style: none;
                .payment-option {
                    margin: 0 15px;
                    border-bottom: dashed 1px $dark-gray-medium;
                    &:first-of-type {
                        margin-top: 15px;
                    }
                    &:last-of-type {
                        border-bottom: 0;
                        margin-bottom: 15px;
                    }
                    label {
                        color: #333;
                    }
                }

            }
            #conditions-to-approve {
                margin: 30px 0;
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }
            }
            #ifthenpay-mbway-payment-form {
                i {
                    display: none;
                }
                .input-container {
                    margin-left: 30px;
                }
            }
        }
    }
    #js-checkout-summary {
        h4 {
            color: #333;
        }
        .cart-summary-products {
            .btn {
                width: 100%;
                background: $secondary;
                color: $white;
                &:before {
                    display: inline-block;
                    width: 20px;
                    font-weight: 600;
                    content: "+";
                }
                &[aria-expanded="true"]{
                    &:before {
                        content: "-";
                    }
                }
            }
            small {
                font-size: 0.7rem;
                display: block;
                margin-top: 5px;
            }
            #cart-summary-product-list {
                padding-bottom: 10px;
                border-bottom: dashed 1px $dark-gray-light;
                margin-bottom: 15px;
                .item {
                    align-items: center;
                    margin-bottom: 5px;
                    .image {
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .description {
                        font-size: 0.9rem;
                    }
                    .price {
                        text-align: right;
                    }
                }
            }
        }
        .cart-summary-subtotals {
            display: flex;
            justify-content: space-between;
        }
        
        .block-promo {
            margin: 30px 0;
            .cart-voucher {
              .promo-code-button {
                border: solid 1px $secondary;
                background-color: $dark-gray-lighter;
                margin-bottom: -1px;
                color: $secondary;
                text-transform: uppercase;
                transition: $transition-quick;
                font-size: 0.9rem;
                &[aria-expanded="true"] {
                  border-bottom:solid 1px $white;
                  i {
                    transform: rotate(90deg);
                    transition: $transition-quick;
                  }
                }
              }
              #promo-code {
                background-color: $dark-gray-lighter;
                border: solid 1px $secondary;
                padding: 0 15px;
                text-align: left;
                h4 {
                  color: $secondary;
                  font-size: 1.3rem;
                  margin-bottom: 5px;
                }
                p {
                  font-size: 0.8rem;
                  color: $dark-gray-medium;
                }
                ul {
                  list-style: none;
                  padding: 0;
                  margin: 0;
                  li {
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                    border-bottom: dashed 1px $secondary;
                    .label {
                        .code {
                          border: solid 1px $secondary;
                          color: $secondary;
                        }
                        font-size: 0.875rem;
                      }
                    &:last-of-type {
                      border-bottom: none;
                      margin-bottom: 0;
                    }
                  }
                }
                &.in {
                  display: block;
                }
                  form {
                    margin: 15px 0;
                }
              }
                .card {
                    background: #FFFFFF;
                    border:dashed 1px $secondary-darker;
                    margin-top: 15px;
                    h5 {
                    //   background: $secondary-darker;
                      color: $secondary;
                      text-align: left;
                      font-weight: normal;
                      margin-bottom: 0;
                    }
                    .card-block {
                      padding-left: 0;
                      margin-bottom: 0;
                    }
                    .cart-summary-line {
                      margin: 15px 0;
                      padding-bottom: 15px;
                      border-bottom:dashed 1px $secondary-darker;
                      text-align: left;
                      list-style: none;
                      display: flex;
                      justify-content: space-between;
                      &:last-of-type {
                        border-bottom: none;
                        margin-bottom: 0;
                      }
                      .btn {
                        border: solid 1px $dark-gray-medium;
                        color: $dark-gray-medium;
                      }
                      .name {
                        flex-grow: 2;
                        padding-left: 15px;
                      }
                      .value {
                        font-weight: 700;
                      }
                    }
                }
                .js-error {
                    display: none;
                }
            }
        }
        .cart-summary-totals {
            margin: 0 15px 15px 15px;
            .cart-summary-line {
                display: flex;
                justify-content: space-between;
                font-size: 0.8rem;
                color: #333;
            }
            .cart-total {
                font-weight: 700;
                font-size: 1.2rem;
            }
        }
    }
}

.confirmation-wrapper {
    margin-top: 15px;
    margin-bottom: 30px;
    strong, a {
        color: #333;
    }
    .title {
        color: #333;
    }
    .page-order-confirmation {
        margin-bottom: 15px;
        .title {
            margin: 0;
            font-size: 1.2rem;
            color: #333;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }
    .payment-info {
        background:$gray-100;
        padding: 15px;
        .title {
            margin-top: 0;
        }
        dl, dt {
            color: #333;
        }
    }
}

#order-summary-content {
    .edit-address {
        color: $medium-gray;
        text-decoration: none;
        border: solid 1px $medium-gray;
        padding: 2px 5px;
        margin-right: 15px;
        font-size: 0.8rem;
        cursor: pointer;
    }
}