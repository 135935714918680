.login-form {
    margin: 12px 0px 25px;
    font-size: 15px;
    h1 {
     color: #515151;       
    }

    .form-fields {
        label {
            font-weight:bold;
            margin: 15px auto 3px;
        }

        input, select {
            width: 100%;
            line-height: 35px;
            vertical-align: middle;
            height: 40px;
            color: $secondary;
            border-color: #cccccc;
        }
        select {
            background-color: $white;
            border-color: #cccccc;
        }
        
        textarea {
            width: 100%;
            color: $secondary;
            height: 200px;
            border-color: #cccccc;
        }

        input[type=file]::file-selector-button {
            background-color: $primary;
            color: $white;
            border: 2px solid $primary;
            cursor: pointer;
        }

        label {
            color: #515151;
        }

        .psgdpr_consent_checkboxes_1 {
            width: 20px;
        }
        .gdpr-message {
            font-weight: normal;
            font-size: 12px;
        }
    }
    .form-footer {
        margin: 15px auto;
        button {
            width: 125px;
            height: 40px;
            background-color: $primary;
            color: $white;
            border: 1px solid $primary;
            cursor: pointer;
            margin-top: 15px;
        }
    }
}


