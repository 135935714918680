// #product {

//   .product-container {
//     padding-bottom: 30px;
//   }
//   .product-manufacturer {
//     font-size: 0.9rem;
//     color: $secondary;
//     margin-bottom: 15px;
//     text-decoration: none;
//   }

//   .product-reference {
//     font-size: 0.9rem;
//     color: $secondary;
//     margin-bottom: 15px;
//   }

//   .title {
//     font-size: 2rem;
//   }

//   .product-prices {
//     margin-bottom: 20px;
//     display: flex;
//     align-items: center;
//     .regular-price {
//       font-size: 1.2rem;
//       color: $secondary;
//       text-decoration: line-through;
//       margin-right: 15px;
//     }
//     .product-price {
//       .base-price {
//         font-size: 1.7rem;
//         font-family: $headings-font-family;
//         font-weight: $headings-font-weight;
//       }
//       .current-price {
//         .current-price-value {
//           font-size: 2rem;
//           font-family: $headings-font-family;
//           font-weight: $headings-font-weight;
//         }
//         .discount {
//           color: $tertiary;
//         }
//       }
//     }
//     .tax-shipping-delivery-label {
//       font-size: 0.8rem;
//       margin-left: 5px;
//       color: $secondary-lighter;
//     }
//   }

//   .right-col {
//     position: relative;
//     .product-flags {
//       list-style: none;
//       padding: 0;
//       margin: 0;
//       position: absolute;
//       right: 5px;
//       top: 5px;
//         .product-flag {
//             text-transform: uppercase;
//             font-size: 0.7rem;
//             padding: 2px 3px;
//             &.new {
//                 background-color: $gray-900;
//                 color: $white;
//             }
//         }
//       }
//     a {
//       border-radius: 8px;
//     }
//   }

//   .product-add-to-cart {
//     .control-label {
//       margin-top: 15px;
//       margin-bottom: 10px;
//       font-size: 0.9rem;
//       font-weight: bold;
//       display: block;
//     }
//     margin: 30px 0;
//    .product-quantity {
//     display: flex;
//     .qty {
//       width: 150px;
//       margin-right: 15px;
//       .bootstrap-touchspin {
//         display: flex;
//         align-items: flex-start;
//       }
//       .btn-touchspin {
//         line-height: 25px;
//         padding: 0.375rem 0.75rem;
//         text-align: center;
//         background-color: $dark-gray-light;
//         color: $secondary;
//         font-size: 1.5rem;
//         font-weight: $headings-font-weight;
//         font-family: $headings-font-family;
//       }
//     }
//     .add {
//       display: inline-flex;
//     }
//     #quantity_wanted {
//       line-height: 25px;
//       padding: 0.375rem 0.75rem;
//       box-shadow: none;
//     }
//     /* Chrome, Safari, Edge, Opera */
//     input::-webkit-outer-spin-button,
//     input::-webkit-inner-spin-button {
//       -webkit-appearance: none;
//       margin: 0;
//     }

//     /* Firefox */
//     input[type=number] {
//       -moz-appearance: textfield;
//     }
//   }
//     button, input {
//       border-radius: 8px;
//     }
//   }

//   .product-discounts {
//     background-color: $dark-gray-lighter;
//     .product-discounts-title {
//       display: block;
//       background-color: $primary;
//       padding:0.5rem 0.5rem;
//       margin: 0;
//     }
//   }



//   .product-variants {
//     .product-variants-item {
//       margin-bottom: 15px;
//       small {
//         display: block;
//         font-size: 0.9rem;
//         margin: 5px 0 2px 0;
//       }
//       .colors {
//         list-style: none;
//         margin: 0;
//         padding: 0;
//         display: flex;
//         .input-color {
//           position: absolute;
//           width: 25px;
//           height: 25px;
//           cursor: pointer;
//           opacity: 0;
//         }
//         .color {
//           width: 25px;
//           height: 25px;
//           display: inline-block;
//           margin-right: 10px;
//           margin-bottom: 10px;
//           box-shadow: $input-shadow;
//         }
//       }
//       .form-control {
//         width: auto;
//       }
//     }
//   }

//   .product_footer {
//     background-color: $gray-200;
//     padding-top: 20px;
//     padding-bottom: 20px;
//   }

//   .product-condition {
//     margin: 10px auto;
//     label {
//       font-weight: bold;
//     }
//     span {
//       background-color: $tertiary;
//     }
//   }
// }


// .product-cover {
//   position: relative;
//   margin-bottom: 20px;

//   img {
//     background: $white;
//   }

//   .layer {
//     position: absolute;
//     top: 0;
//     left: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     height: 100%;
//     text-align: center;
//     cursor: pointer;
//     background: #000;
//     background: rgba(0, 0, 0, 0.1);
//     opacity: 0;
//     @include transition(opacity 0.7s ease-in-out);

//     &:hover {
//       opacity: 1;
//     }
//   }
// }

// #product-modal {
//   .modal-content {
//     padding: 0;
//     background: transparent;
//     border: none;

//     .modal-body {
//       display: flex;
//       margin-left: -60%;

//       .product-cover-modal {
//         background: $white;
//       }

//       .image-caption {
//         width: 100%;
//         padding: 0.625rem 1.25rem;
//         background: $white;
//         border-top: $gray-300 1px solid;

//         p {
//           margin-bottom: 0;
//         }
//       }

//       .thumbnails {
//         position: relative;
//       }

//       .mask {
//         position: relative;
//         z-index: 1;
//         max-height: 47.192rem;
//         margin-top: 2.188rem;
//         margin-bottom: 2.188rem;
//         overflow: hidden;

//         &.nomargin {
//           margin-top: 0;
//         }
//       }
//       .product-images {
//         margin-left: 30px;
//         li.thumb-container {
//           display: block;
//         }

//         img {
//           width: 9.25rem;
//           cursor: pointer;
//           background: $white;

//           &:hover {
//             border: $primary 3px solid;
//           }
//         }
//       }

//       .arrows {
//         position: absolute;
//         top: 0;
//         left: 30px ;
//         z-index: 0;
//         width: 100%;
//         height: 100%;
//         padding-right: 30px;
//         color: $white;
//         text-align: center;
//         cursor: pointer;

//         .arrow-up {
//           position: absolute;
//           top: 0;
//           left: 0;
//           right: 30px;
//           opacity: 0.2;
//           z-index: 99;
//         }

//         .arrow-down {
//           position: absolute;
//           bottom: -2rem;
//           right: 30px;
//           left: 0;
//           z-index: 99;
//         }

//         i {
//           display: inline;
//           line-height: 30px;
//           cursor: pointer;
//           margin-bottom: 5px;
//         }
//       }
//     }
//   }
// }



// #main {
//   .images-container {
//     .js-qv-mask {
//       overflow: hidden;
//       white-space: nowrap;

//       &.scroll {
//         width: calc(100% - 30px);
//         margin: 0 auto 0 0;
//       }
//     }
//   }
// }

// .scroll-box-arrows {
//   display: none;
//   position: relative;

//   &.scroll {
//     display: block;
//   }

//   ion-icon {
//     position: absolute;
//     bottom: 1.625rem;
//     height: 100px;
//     line-height: 100px;
//     cursor: pointer;
//     margin-bottom: 5px;
//   }

//   .left {
//     left: 0;
//   }

//   .right {
//     right: 0;
//   }
// }


// @include media-breakpoint-down(md) {
//   .product-cover {
//     img {
//       width: 100%;
//       height: auto;
//     }
//   }

//   #product-modal .modal-content .modal-body {
//     flex-direction: column;
//     margin-left: 0;

//     .arrows {
//       display: none;
//     }
//   }

//   #product-modal .modal-content .modal-body .image-caption {
//     width: 100%;
//   }

//   #blockcart-modal {
//     .modal-dialog {
//       width: calc(100% - 20px);
//     }

//     .modal-body {
//       padding: 1.875rem;
//     }
//   }

//   .product-images > li.thumb-container > .thumb:not(.selected) {
//     border: none;
//   }
// }

// @include media-breakpoint-down(sm) {
//   #blockcart-modal {
//     .divide-right {
//       border-right: none;
//     }

//     .modal-body {
//       padding: 1rem;
//     }
//   }
// }

// .prd {
//   position: relative;
//   padding: 15px;
//   overflow: hidden;
//   height: 100%;
//   text-align: center;

//   .add-cart {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;

//     ion-icon {
//       font-size: 17px;
//     }

//     .add-to-cart {
//       padding: 12px 0;
//       background-color: $primary;
//       border: 0;
//     }

//     .add-to-cart:hover {
//       background-color: $quaternary;
//       color: $white;
//     }
//   }
//   .out-of-stock {
//     padding: 10px 0;
//     background-color: #9fa4aa;
//     width: 100%;
//   }
// }


#main {
  #content {
    position: relative;
    .product-flags {
      position: absolute;
      top: 0;
      color: $white;
      z-index: 1;
      .on-sale {
          background-color: #f13340;
          padding: 3px 5px;
      }
      .discount {
        display: none;
      }
  
      .new {
          background-color: $primary;
          padding: 3px 5px;
      }
    }
    .product-images {
      margin-left: 0;
      padding-left: 0;
      > li.thumb-container {
        display: inline;
    
        > .thumb {
          margin-bottom: 15px;
          cursor: pointer;
    
          .images-container & {
            margin-right: 0.8125rem;
          }
    
          &.selected,
          &:hover {
            border: $primary 3px solid;
          }
        }
      }
    }
    .scroll-box-arrows {
      display: none;
      position: relative;

      &.scroll {
        display: block;
      }

      i {
        position: absolute;
        bottom: 1.625rem;
        height: 100px;
        line-height: 100px;
        cursor: pointer;
        margin-bottom: 5px;
      }

      .left {
        left: 0;
      }

      .right {
        right: 0;
      }
    }
  }
  .center-col {
    .product-manufacturer {
      span {
        a {
          text-decoration: none;
          font-size: 0.9rem;
          color: #777;
        }
        a:hover {
          color: #000;
        }
      }
    }
    .prod-title {
      color: #3a3939;
      font-weight: 300;
    }
    .product-reference {
      font-size: 0.9rem;
      label {
        color: #777;
      }
      span {
        color: #777;
      }
    }
    .product-description {
      font-size: 0.9rem;
      color: #777;
    }
    .social-sharing {
      .social-network-list {
        display: flex;
        padding: 15px 0;
        li {
          margin-right: 15px;
          a {
            img {
              width: 25px;
            }
          }
        }
      }
    }
    .print-prod {
      text-decoration: none;
      color: #515151;
    }
  }
  .right-col {
    border: 1px solid #cccccc;
    margin-left: -10px;
    height: 780px;
    .product-prices {
      display: flex;
      flex-direction: column;
      align-items: end;
      position: relative;
      padding: 15px;
      .current-price-value {
        color: $primary;
        font-weight: 600;
        font-size: 29px;
        .tax-label {
          font-size: 12px;
          font-weight: 200;
          color: #777;
        }
      }
      .discount-percentage {
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 50%;
        position: absolute;
        top: -30px;
        left: -30px;
        text-align: center;
        background-color: $primary;
        color: white;
      }
      .regular-price {
        font-size: 13px;
        text-decoration: line-through;
        color: #777;
      }
    }
    .product-information {
      padding: 15px;
      .product-variants {
        .product-variants-item {
          .control-label {
            color: #333;
            font-size: 0.9rem;
          }
          ul {
            li {
              display: inline-block;
            }
          }
        } 
      }
    }
    .product-add-to-cart {
      .control-label {
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 0.9rem;
        font-weight: bold;
        display: block;
      }
      margin: 30px 0;
      .product-quantity {
        display: flex;
        .qty {
          width: 150px;
          margin-right: 15px;
          .bootstrap-touchspin {
            display: flex;
            align-items: flex-start;
          }
          .btn-touchspin {
            line-height: 25px;
            padding: 0.375rem 0.75rem;
            text-align: center;
            background-color: $dark-gray-light;
            color: $secondary;
            font-size: 1.5rem;
            font-weight: $headings-font-weight;
            font-family: $headings-font-family;
          }
        }
        .add {
          display: inline-flex;
        }
        #quantity_wanted {
          line-height: 25px;
          padding: 0.375rem 0.75rem;
          box-shadow: none;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      }
    }
  }
  @include media-breakpoint-down(xl) {
      .right-col {
        height: auto;
      }
  }

  .more-info {
    margin-top: 25px;
    color: #515151;
    .desc-title {
      margin-bottom: 0;
      font-weight: 400;
    }
    .prod-description {
      padding: 20px;
      font-size: 0.9rem;
    }
  }

  .input-color {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  span.color {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid $secondary-lighter;
  }

  .product-cover {
    position: relative;
    margin-bottom: 20px;
  
    img {
      background: $white;
    }
  
    .layer {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      cursor: pointer;
      background: #000;
      background: rgba(0, 0, 0, 0.1);
      opacity: 0;
      @include transition(opacity 0.7s ease-in-out);
  
      &:hover {
        opacity: 1;
      }
    }
  }
  
  #product-modal {
    .modal-content {
      padding: 0;
      background: transparent;
      border: none;
  
      .modal-body {
        display: flex;
        margin-left: -60%; 
        .product-cover-modal {
          background: $white;
        }
  
        .image-caption {
          width: 100%;
          padding: 0.625rem 1.25rem;
          background: $white;
          border-top: $gray-300 1px solid;
  
          p {
            margin-bottom: 0;
          }
        }
  
        .thumbnails {
          position: relative;
        }
  
        .mask {
          position: relative;
          z-index: 1;
          max-height: 47.192rem;
          margin-top: 2.188rem;
          margin-bottom: 2.188rem;
          overflow: hidden;
  
          &.nomargin {
            margin-top: 0;
          }
        }
        .product-images {
          margin-left: 30px;
          li.thumb-container {
            display: block;
          }
  
          img {
            width: 9.25rem;
            cursor: pointer;
            background: $white;
  
            &:hover {
              border: $primary 3px solid;
            }
          }
        }
  
        .arrows {
          position: absolute;
          top: 0;
          left: 30px ;
          z-index: 0;
          width: 100%;
          height: 100%;
          padding-right: 30px;
          color: $white;
          text-align: center;
          cursor: pointer;
  
          .arrow-up {
            position: absolute;
            top: 0;
            left: 0;
            right: 30px;
            opacity: 0.2;
            z-index: 99;
          }
  
          .arrow-down {
            position: absolute;
            bottom: -2rem;
            right: 30px;
            left: 0;
            z-index: 99;
          }
  
          i {
            display: inline;
            line-height: 30px;
            cursor: pointer;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .right-col, .center-col {
      margin-top: 35px;
    }
    .left-col {
      .product-images {
        margin-top: 0;
      }
    }
    .right-col {
      margin-left: 0;
      .discount-percentage {
        top: -15px!important;
        left: -15px!important;
      }
    }
  }

  #product-modal {
    .modal-content {
      padding: 0;
      background: transparent;
      border: none;

      .modal-body {
        display: flex;
        margin-left: -60%;

        .product-cover-modal {
          background: $white;
        }

        .image-caption {
          width: 100%;
          padding: 0.625rem 1.25rem;
          background: $white;
          border-top: $gray-300 1px solid;

          p {
            margin-bottom: 0;
          }
        }

        .thumbnails {
          position: relative;
        }

        .mask {
          position: relative;
          z-index: 1;
          max-height: 47.192rem;
          margin-top: 2.188rem;
          margin-bottom: 2.188rem;
          overflow: hidden;

          &.nomargin {
            margin-top: 0;
          }
        }
        .product-images {
          margin-left: 30px;
          li.thumb-container {
            display: block;
          }

          img {
            width: 9.25rem;
            cursor: pointer;
            background: $white;

            &:hover {
              border: $primary 3px solid;
            }
          }
        }

        .arrows {
          position: absolute;
          top: 0;
          left: 60px ;
          z-index: 0;
          width: 85%;
          height: 100%;
          padding-right: 30px;
          color: $white;
          text-align: center;
          cursor: pointer;

          .arrow-up {
            position: absolute;
            top: 0;
            left: 0;
            right: 30px;
            opacity: 0.2;
            z-index: 99;
          }

          .arrow-down {
            position: absolute;
            bottom: -2rem;
            right: 30px;
            left: 0;
            z-index: 99;
          }

          i {
            display: inline;
            line-height: 30px;
            cursor: pointer;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .images-container {
    .js-qv-mask {
      overflow: hidden;
      white-space: nowrap;

      &.scroll {
        width: calc(100% - 30px);
        margin: 0 auto 0 0;
      }
    }
  }
}

#blockcart-modal {
  .modal-dialog {
    max-width: 65%;
  }
  @include media-breakpoint-down(sm) {
    .modal-dialog {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @include media-breakpoint-down(lg) {
    .cart-content {
      margin-top: 10px;
    }
  }
  @include media-breakpoint-down(sm) {
    .cart-content-btn {
      display: flex;
      flex-direction: column;
      .continue-shopping {
        margin-bottom: 10px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .modal-title {
      text-align: right;
    }
  }
  .modal-img {
    img {
      width: 100%;
    }
  }
  .crossselling {
    .products {
      display: flex;
      justify-content: space-evenly;
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        .product-miniature {
          margin-bottom: 35px;
          p, .product-price-and-shipping {
            display: none;
          }
        }
      }
      .product-miniature {
        .highlighted-informations, .product-flags {
          display: none;
        }
      }
    }
  }
  .close {
    background-color: transparent;
    border: none;
  }
  .continue-shopping {
    background-color: #575757;
    color: $white;
  }
  h5 {
    color: #515151;
    font-weight: 500;
  }
}


@media only print {
	#head {
    display: none;
	}
  #wrapper {
    .breadcrumb {
      display: none;
    }
    .left-col {
      display: flex;
      justify-items: center;
      .product-cover {
        width: 100%;
      }
      .js-qv-mask {
        overflow:visible;
        white-space:unset;
      }
      .scroll-box-arrows {
        i {
          display: none;
        }
      }
    }
    .center-col {
      a, .social-sharing {
        display: none;
      }
    }
    .right-col {
      .product-add-to-cart {
        display: none;
      }
    }
  }
	#footer {
		.row {
			>div, >section {
				display:none;
				&.block-contact {
					display:block;
					width:100%;
					border-left:none;
					border-top:1px solid #515151;
					padding-top:20px;
				}
			}
		}
    .bottom-half {
      display: none;
    }
	}
}