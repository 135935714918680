.left-col-categories, .left-col-categories-mobile {
    margin-top: 12px;
    margin-bottom: 25px;
    h3 {
        font-size: 1.2rem;
    }
    a {
        color: #515151;
        text-decoration: none;
        text-decoration-color: none;
        display: block;
        &:hover {
            color:#515151;
        }
    }
    .category-top-menu {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
        }
        .category-sub-menu {
            padding: 0;
            li {
                list-style: none;
                position: relative;
                &:hover {
                    background-color: $dark-gray-light;
                }
                a {
                    padding: 5px 5px;
                    font-size: 1rem;
                }
                li {
                    &:hover {
                        text-decoration: underline;
                    }
                    a {
                        padding-left: 30px;
                        font-size: 0.9rem;
                    }
                    li {
                        a {
                            padding-left: 45px;
                        }
                        li {
                            a {
                                padding-left: 60px;
                            }
                        }
                    }
                }
                .collapse-icons {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    color: #515151;
                    padding: 5px 5px;
                    &[aria-expanded="false"] {
                        .add {
                            display: block;
                        }
                        .minus {
                            display: none;
                        }
                    }
                    &[aria-expanded="true"] {
                        .add {
                            display: none;
                        }
                        .minus {
                            display: block;
                        }
                    }
                }
            }
        }
    }
  }
  

.left-col-categories-mobile {
    position: relative;
    margin-bottom: 17px ;
    h3 {
        line-height: 1;
    }
    .mobile-icons {
        position: absolute;
        right: 0;
        top: -5px;
        // bottom: 0;
        color: #515151;
        padding: 5px 5px;
        &[aria-expanded="false"] {
            .add {
                display: block;
            }
            .minus {
                display: none;
            }
        }
        &[aria-expanded="true"] {
            .add {
                display: none;
            }
            .minus {
                display: block;
            }
        }
    }
}