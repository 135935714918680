.block-categories {
    margin-top: 12px;
    a {
        color: #515151;
        text-decoration: none;
        text-decoration-color: none;
        display: block;
        &:hover {
            color:#515151;
        }
    }
    .mobile-category-top-menu {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
        }
        .mobile-category-sub-menu {
            padding: 0;
            background-color: $dark-gray-lighter;
            li {
                list-style: none;
                position: relative;
                &[data-depth="0"] {
                    border-bottom: solid 1px $dark-gray-light;
                }
                a {
                    padding: 10px 10px;
                    font-family: $headings-font-family;
                    font-weight: $headings-font-weight;
                    font-size: 1rem;
                    &.category-sub-link {
                        border-bottom: solid 1px $dark-gray-light;
                    }
                }
                li {
                    // background-color: $primary;
                    .collapse-icons {
                        color: $secondary;
                    }
                    a {
                        color: $secondary;
                        padding-left: 30px;
                        font-family: $font-family;
                        font-weight: $font-weight;
                        font-size: 0.9rem;
                    }
                    li {
                        a {
                            padding-left: 45px;
                        }
                        li {
                            a {
                                padding-left: 60px;
                            }
                        }
                    }
                }
                .collapse-icons {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    color: #515151;
                    padding: 15px 15px;
                    &[aria-expanded="false"] {
                        .add {
                            display: block;
                        }
                        .minus {
                            display: none;
                        }
                    }
                    &[aria-expanded="true"] {
                        .add {
                            display: none;
                        }
                        .minus {
                            display: block;
                        }
                    }
                }
                
            }
        }
    }
      
}