#footer {
    background-color: #f5f5f5;
    color: #777777;
    padding: 20px 0;
    font-size: 0.9rem;
    .footer-container {
        #block_myaccount_infos {
            text-align: right;
            margin-left: auto;
            h6 {
                font-weight: 500;
                text-transform: uppercase;
            }
            ul {
                padding: 0;
                li {
                    a {
                        text-decoration: none;
                        color: #777777;
                    }
                }
            }
        }
        .linklist {
            h6 {
                font-weight: 500;
                text-transform: uppercase;
            }
            ul {
                padding: 0;
                li {
                    a {
                        text-decoration: none;
                        font-size: 0.9rem;
                        color: #777777;
                    }
                }
            }
        }
        .block-contact{
            h6 {
                color: #777777;
                font-weight: 500;
                text-transform: uppercase;
            }
            ul {
                padding: 0;
                li {
                    a {
                        text-decoration: none;
                        color: #777777;
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            #block_myaccount_infos, .linklist, .block-contact  {
                text-align: center;
            }
        }
        .storeinformation {
            padding-top: 15px;
            display: flex;
            flex-direction: column;
            h6 {
                font-weight: 500;
                text-transform: uppercase;
            }
        }
        .ps-social-follow {
            display: flex;
            flex-direction: column;
            align-items: center;
            h6 {
                font-weight: 500;
                text-transform: uppercase;
            }
            ul {
                li {
                    margin-left: 0px;
                    margin-right: 15px;
                    filter: invert(1);
                }
            }
        }
        .email_subscription {
            padding-top: 15px;
            margin-left: auto;
            display: flex;
            flex-direction: column;
            align-items: end;
            h6 {
                font-weight: 500;
                text-transform: uppercase;
            }
            .form-group {
                position: relative;
                .email {
                    height: 45px;
                    background: #908f8f;
                    color: $white;
                    padding: 10px 43px 10px 12px;
                    border: none;
                    width: 100%;
                }
                .subs {
                    border: none;
                    background: none;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 5px;
                    font-size: 25px;
                    i {
                        color: $white;
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            .storeinformation, .email_subscription  {
                align-items: center;
            }
        }
        .footerbottom {
            display: flex;
            padding-top: 15px;
            .conf {
                width: 50%;
            }
            .livrorec {
                margin-left: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            @include media-breakpoint-down(md) {
                .conf {
                    width: 75%;
                    img {
                        width: 100%;
                    }
                }
                .livrorec {
                    width: 25%;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        #block_myaccount_infos, .linklist {
            position: relative;
            margin-bottom: 15px;
            .mobile-icons-link {
                position: absolute;
                right: 0;
                top: -7px;
                padding: 5px 5px;
                &[aria-expanded="false"] {
                    .minus {
                        display: none;
                    }
                }
                &[aria-expanded="true"] {
                    .add {
                        display: none;
                    }
                }
            }
        }
    }
}
