.product-miniature {
    position: relative;
    text-align: center;
    overflow: hidden;
    height: 100%;
    margin-bottom: 70px;

    a {
        text-decoration: none;
    }
    img {
        width: 100%;
        height: auto;
    }
    .wishlist-button-add {
        display: none;
    }

    .variant-links {
        display: none;
    }

    .product-flags {
        position: absolute;
        top: 0;
        color: $white;

        .on-sale {
            background-color: #f13340;
            padding: 3px 5px;
        }
    
        .new {
            background-color: $primary;
            padding: 3px 5px;
        }
        .discount {
            margin-top: 10px;
            background-color: $dark-gray;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            position: absolute;
            right: 23px;
            line-height: 50px;
        }
    }
    .product-description {
        margin-top: 10px;
        p {
            font-size: 0.9rem;
            color: #515151;
        }
        .manufacturer {
            color: #999;
        }
        .product-price-and-shipping {
            font-size: 18px;
            position: absolute;            
            left:0;
            right:0;
            margin-left:auto;
            margin-right:auto;
            bottom: 35px;

            .discount-percentage {
                display: none;
            }
            .regular-price {
                font-size: 12px;
                text-decoration: line-through;
            }
            .price {
                font-weight: bold;
                color: $primary;
            }    
        }
        .highlighted-informations {
            .variant-links {
                display: flex;
                justify-content: center;
                a {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border: 1px solid $dark-gray-lighter;
                    margin-right: 5px;
                }
            }
        }
        .product-title {
            a {
                color: #3a3939;
            }
        }
    }
}

// SCSS code
body {
    #prices-drop & {
        .prices-drop-date {
            display: block; // or any other desired styles
        }
    }
    &:not(#prices-drop) {
        .prices-drop-date {
            display: none; // hide the element if body id is not "prices-drop"
        }
    }
}
  