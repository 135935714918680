#left-column-cat, #left-col-content, #left-column, #left-col-sitemap, .left-col-manufacturer {
    color: #515151;
    .viewed-products {
        padding-bottom: 15px;
        h5 {
            font-size: 1.2rem;
        }
        .product-miniature {
            margin-bottom: 15px;
        }
        div.js-product {
            margin-top: 10px;
            width: 100%;
            .thumbnail-container {
                display: flex;
                .thumbnail-top {
                    width: 40%;
                }
                .product-description {
                    margin-top: 0;
                    .product-title {
                        font-weight: 500;
                    }
                    width: 60%;
                    text-align: left;
                    padding-left: 5px;
                }
                .product-price-and-shipping {
                    display: none;
                }
                .highlighted-informations, .product-flags {
                    display: none;
                }
            }
        }  
    }
    .tags_block {
        position: relative;
        padding: 15px 0;
        h5 {
            font-weight: 600;
            padding: 0;
            text-transform: none;
            border-bottom: none;
            font-size: 1.2rem;
        }
        .block-tags {
            .mobile-icons {
                position: absolute;
                right: 0;
                top: -7px;
                // bottom: 0;
                color: #515151;
                padding: 5px 5px;
            }
            &[aria-expanded="true"] {
                .add {
                    display: none;
                }
            }
            &[aria-expanded="false"] {
                .minus {
                    display: none;
                }
            }
        }

        #block_content {
            padding: 0;
            .tag-list {
                display: inline-block;
                margin: 5px;
                a {
                    color: #515151;
                }
                a:hover {
                    color: black;
                }
            }
        }
    }
    section.featured-products {
        .new-prod {
            margin-top: 10px;
            .product-miniature {
                margin-bottom: 15px;
                .thumbnail-container {
                    display: flex;
                    .thumbnail-top {
                        width: 40%;
                    }
                    .product-description {
                        margin-top: 0;
                        .product-title {
                            font-weight: 500;
                        }
                        width: 60%;
                        text-align: left;
                        padding-left: 5px;
                        .highlighted-informations {
                            display: none;
                        }
                        .product-price-and-shipping {
                            font-size: 0.9rem;
                            position: relative;
                            bottom: 0;
                        }
                    }
                }
                .product-flags {
                    display: none;
                }
            }
        }
        .all-product-link {
            padding: 10px 30px;
            background-color: #6f6f6f;
            font-weight: 600;
            color: white;
            margin-top: 15px;
        }
        .all-product-link:hover {
            border: 1px solid #515151;
        }
    }
    // @include media-breakpoint-down(md) {
    //     .viewed-products, .tags_block, section.featured-products {
    //         display: none;
    //     }
    // }
    @include media-breakpoint-down(lg) {
        .viewed-products {
            div.js-product {
                margin-top: 10px;
                width: 100%;
                .thumbnail-container {
                    display: block;
                    .thumbnail-top {
                        width: 100%;
                    }
                    .product-description {
                        margin-top: 0;
                        .product-title {
                            font-size: 0.9rem;
                        }
                        font-size: 0.8rem;
                        width: 100%;
                        text-align: left;
                    }
                }
            }  
        }
        .tags_block {
            h5 {
                font-size: 1.2rem;
            }
            .block_content {
                .tag-list {
                    display: inline-block;
                    margin: 2px;
                    a {
                        color: #515151;
                        font-size: 0.8rem;
                    }
                    a:hover {
                        color: black;
                    }
                }
            }
        }
        section.featured-products {
            .new-prod {
                .product-miniature {
                    .thumbnail-container {
                        display: block;
                        .thumbnail-top {
                            width: 100%;
                        }
                        .product-description {
                            .product-title {
                                font-size: 0.9rem;
                            }
                            p {
                                font-size: 0.8rem;
                            }
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .viewed-products-mobile, .mobile-layout {
        position: relative;
        padding-bottom: 8px;
        h5 {
            font-size: 1.2rem;
            line-height: 1;
        }
        .mobile-icons {
            position: absolute;
            right: 0;
            top: -7px;
            // bottom: 0;
            color: #515151;
            padding: 5px 5px;
            &[aria-expanded="false"] {
                .minus {
                    display: none;
                }
            }
            &[aria-expanded="true"] {
                .add {
                    display: none;
                }
            }
        }
        div.js-product {
            margin-top: 15px;
            width: 100%;
            .product-miniature {
                margin: 0;
            }
            .thumbnail-container {
                display: flex;
                .thumbnail-top {
                    img {
                        height: 150px;
                        width: auto;
                    }
                }
                .product-description {
                    margin-top: 0;
                    padding-left: 20px;
                    .product-title {
                        font-size: 0.9rem;
                    }
                    font-size: 0.8rem;
                    text-align: left;
                    .highlighted-informations, .product-price-and-shipping {
                        display: none;
                    }
                }
                .product-flags {
                    display: none;
                }
            }
        }  
    }    
    @include media-breakpoint-down(md) {
        .tags_block {
            padding: 0;
            margin-top: 0px;
            h5 {
                line-height: 1;
            }
        }
    }
}

