#head {
    padding-top: 25px;
    .language-selector {
        padding-top: 5px;
        ul {
            display: flex;
            flex-direction: row;
            justify-content: end;
        }
        li {
            margin-left: 10px;
        }

        img {
            width: 25px;
        }
    }
    .cofinanciamento {
        img {
            width: 70%;
        }
    }
    @include media-breakpoint-down(md) {
        text-align: center;
        .logo {
            img {
                width: 80%;
            }
        }
        .cofinanciamento {
            padding: 25px 0;
            img {
                width: 40%;
            }
        }
        .language-selector {
            padding: 0;
        }
        .mobile-btn {
            text-align: left;
        }
    }
    .login-cart {
        justify-content: center;
        @include media-breakpoint-down(lg) {
            justify-content: start;
        }
        @include media-breakpoint-down(md) {
            justify-content: center;
        }
        @include media-breakpoint-down(sm) {
            justify-content: start;
        }
    }

    @include media-breakpoint-up(md) {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100; /* Ensure the header stays on top of other content */
        background-color: #fff; /* Adjust background color as needed */
        /* Add any other styling properties */  
    }
}
