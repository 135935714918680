#search_widget {
    width: 100%;
    z-index: 1000;
    form {
        position: relative;
        input {
            padding: 5px 10px;
            width: 100%;
            padding-right: 30px;
            border: 1px solid $dark-gray-light;
            color: $secondary;
            font-size: 0.9rem;
            height: 50px;
            &[placeholder] {
                @include media-breakpoint-down(lg) {
                    font-size: 0.8rem;
                }
            }
        }
        @include media-breakpoint-down(md) {
            input {
                margin-bottom: 15px;
            }
        }

        .btn {
            color: $secondary;
            font-weight: bolder;
            position: absolute !important;
            right: 5px !important;
            height: 50px;
            i {
                @include media-breakpoint-down(lg) {
                    font-size: 1.2rem;
                }
            }

        }
        span {
            position: absolute;
            right: 10px;
            top: 5px;
            bottom: 0;
        }
    }
}

.ui-menu {
    border-radius: 0 !important;
    border-color: $primary !important;
    .ui-menu-item {
        list-style-image: none !important;
        padding: 0 !important;
        margin: 0 !important;
        a {
            padding: 2px 5px !important;
            font-size: 1rem;
        }
    }
} 

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
    background-image: none !important;
    background-color: $dark-gray-light !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 0!important;
}