.ps-social-follow {
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    display: flex;
    h4 {
        margin: 0;
    }
    ul {
        padding: 0;
        margin: 0 !important;
        align-items: center;
        display: flex;
        li {
            list-style: none;
            margin-left: 15px;
            @include media-breakpoint-down(sm) {
                margin: 10px;
            }
            img {
                width: 30px;
                filter: invert(100%);
            }
        }
    }
}