#subcategories {
    border: none;
    margin-bottom: 20px;
    padding: 0;
    .subcategory-heading {
        color: $primary;
    }
    .sub-cat {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        
        .subcategory-image {
            width: 100%;
            img {
                width: 100%;
                height: auto;
            }
        }
        .subcategory-image:hover {
            opacity: 0.8;
        }

        h6 {
            width: 100%;
            height: 65px;
            background-color: $primary;
            font-weight: 600;
            text-align: center;
            padding-top: 10px;
            .subcategory-name {
                text-decoration: none;
                color: $white;
            }
        }
    }
}

#js-product-list-header {
    .block-category {
        border: none;
        h3 {
            color: $tertiary;
        }
    } 
}


.subcat {
    #js-product-list-header {
        color: #515151;
    }
    border: none;
    margin-top: 12px;
    .card {
        padding: 0;
    }
}

.pagination {
    padding: 15px 0;
    font-size: 0.8rem;
    color: $secondary-light;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $dark-gray-light;
    .page-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        li {
            margin: 5px;
            font-size: 0.9rem;
            &.current {
                font-weight: bold;
            }
        }
        a {
            color: $secondary;
            text-decoration: none;
        }
    }
}

#wrapper {
    margin-bottom: 35px;
}

