#account-page {
    color: #515151;
    h2 {
        font-size: 1.75rem;
    }
    p {
        font-size: 0.9rem;
    }
    .link-item {
        margin-bottom: 20px;
        a {
            border: solid 1px $dark-gray-light;
            background: $white;
            display: block;
            padding: 15px;
            color: #515151;
            text-decoration: none;
            display: flex;
            i {
              margin-right: 15px;
              font-size: 2.2rem;
              color: $secondary;
            }
            h4 {
                margin-bottom: 0;
            }
        }
    }
}

.page-header {
    h1 {
    font-size: 1.75rem;
    color: #515151;
    }   
}

#customer-form {
    border: 1px solid #d6d4d4;;
    padding: 20px;
    @include media-breakpoint-up(lg) {
        max-width: 50%;
    } 

    .checkbox-field {
        display: flex;
        margin-bottom: 10px;
        align-items: flex-start;
        p {
            margin: 0;
        }
        span {
            margin-left: 10px;
        }
    }
}

.address-form {
    border: 1px solid #d6d4d4;;
    padding: 20px;
    @include media-breakpoint-up(lg) {
        max-width: 50%;
    } 
}

#addresses {
    .card {
        margin-bottom: 20px;
    }
    .addresses-footer {
        a {
            border: solid 1px $dark-gray-light;
            background: $white;
            display: block;
            padding: 15px;
            color: $dark-gray;
            text-decoration: none;
            display: flex;
            width: auto;
            i {
              margin-right: 15px;
              font-size: 2.2rem;
            }
            h4 {
                margin-bottom: 0;
            }
        }
    }
    .address-footer {
        a {
            color: $medium-gray;
            text-decoration: none;
            border: solid 1px $medium-gray;
            padding: 2px 5px;
            margin-right: 15px;
            font-size: 0.8rem;
        }
    }

}

#module-psgdpr-gdpr {
    .card {
        padding: 15px;
        margin-bottom: 20px;
    }
    .exports {
        a {
            border-radius: 8px;
        }
    }
}

.page-footer {
    margin-top: 20px;
    .btn-homepage {
        background: #575757;
        color: $white;
    }
    .btn-homepage:hover {
        color: $white;
    }
}


.gdpr {
    h2 {
        color: #3a3939;
    }
}