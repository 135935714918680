.page {
    padding-top: 35px;
    padding-bottom: 35px;
}

#cms {
    color: #515151;
    .page-header {
        display: none;
    }
    #left-col-content {
        .block-categories {
            margin-top: 0;
        }
    }
    #right-col-content {
        p {
            font-size: 12px;
        }
        h1 {
            font-size: 33px;
            font-weight: 500;
            margin-bottom: 25px;
        }
        h2 {
            font-size: 27px;
            font-weight: 500;
            margin-bottom: 9px;
        }
        h3 {
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 17px;
        }
        h4 {
            font-size: 17px;
            font-weight: 500;
        }
        table {
            td {
                font-size: 12px;
                padding: 9px 10px;
            }
        }
    }
}
.cms-id-14 {
    #right-col-content {
        p {
            font-size: 0.9rem!important;
        }
    }
}