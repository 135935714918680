// Google font
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

// Bootstrap overrides
// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1464px
) !default;
// scss-docs-end container-max-widths
$primary: #455778;
$secondary: #000000;
$tertiary:#515151;
$quaternary:#0f3b60;
$link-color: $tertiary;
//
$primary-dark: darken($primary, 5%);
$white: #fff;
$dark-gray: #333; 
$dark-gray-medium: lighten($dark-gray, 30%);
$dark-gray-light: lighten($dark-gray, 65%);
$dark-gray-lighter: lighten($dark-gray, 75%);
$medium-gray: lighten($dark-gray, 45%);
$secondary-lightest: lighten($secondary, 90%);
$secondary-lighter: lighten($secondary, 51%);
$secondary-darker: lighten($secondary, 30%);
$secondary-light: lighten($secondary, 70%);
$secondary-dark: darken($secondary, 5%);
$input-border: solid 1px $dark-gray-light;
$slider-container: 470px;
$font-family:  'Arial', sans-serif;
$headings-font-family: 'Roboto Condensed', sans-serif;
// $font-family:  'Noto Sans', sans-serif;
$font-weight: 400;
// $headings-font-family: 'Saira Semi Condensed', sans-serif;
$headings-font-weight: 600;
$table-box-shadow: 0 0 .3rem 0.1rem rgba(#000, .15) ;
$box-shadow: 0 .3rem 0.6rem rgba(#000, .20) ;
$box-shadow-medium: 0 .2rem 0.5rem rgba(#000, .15) ;
$box-shadow-small: 0 0 0.3rem rgba(#000, .09) ;
$primary-transparent: rgba(0, 0, 0, 0.8);
$transition-base: all 0.5s ease ;
$transition-quick: all 0.2s ease ;
$border-secondary: solid 1px $secondary;
$jumbotron-padding: 0;
$jumbotron-bg: $secondary;
$tooltip-bg: $secondary;
$content-large-padding: 0 15px;
$table-hover-bg: $white;
$border-radius:0;
$border-radius-sm:0;
$border-radius-lg:0;
// Bootstrap and its default variables
@import "bootstrap/scss/bootstrap";
//inputs
$input-shadow: 3px 3px 0px 0px $secondary;
$input-shadow-hover: 3px 3px 0px 0px $primary;
$no-shadow: 0px 0px 0px 0px $primary;
$no-shadow-hover: 3px 3px 0px 0px $primary;
$text-shadow: 0px 0px 0px $primary;
$text-shadow-hover: 3px 3px 0px $primary;
$input-color: $gray-600;
$input-border: solid 1px $secondary;
$input-border-hover: solid 1px $primary;
$modal-content-border-radius: 0px;
@import "components/animate";
@import "components/header"; 
@import "components/languageselector";
@import "components/mainmenu";
@import "components/imageslider";
// @import "components/advancedsearch";
@import "components/footer";
@import "components/searchbar";
@import "components/forms";
@import "components/homeproducts";
@import "components/homecms";
@import "components/customersignin";
@import "components/productminiature";
@import "components/brandlist";
@import "components/category";
@import "components/categorytree";
@import "components/breadcrums";
@import "components/facetedsearch";
@import "components/splide-core.min.css";
@import "components/product";
@import "components/socialfollow";
@import "components/contact";
@import "components/cms";
@import "components/my-account";
@import "components/history";
@import "components/brands";
@import "components/cart";
@import "components/checkout";
@import "components/hideprices";
@import "./components/jquery.bxslider";
@import "./components/lightbox";
@import "./components/contactform";
@import "./components/dropdown";
@import "./components/customtext";
@import "./components/leftcolumn";
@import "./components/mobilemenu";
@import "./components/sitemap.scss";

// @import "./components/infinitescroll";
@import url('https://fonts.googleapis.com/css2?family=Saira+Semi+Condensed:wght@600&family=Noto+Sans:wght@400&display=swap');

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}


// Custom Styles

// stick footer to bottom
html {
  height: 100%;
}
body {
	font-family: $font-family;
  color: $secondary;

  // stick footer to bottom
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

// stick footer to bottom
footer {
  margin-top: auto;
}

li {
  list-style-type: none;
}

a {
   outline: 0;
   color: $primary;
   text-decoration-color: $primary;
   transition: $transition-base;
   &:hover {
    color: $secondary;
    transition: $transition-quick;
   }
}

.modal-content {
  border-radius: $modal-content-border-radius;
}
.header-title {
  margin-bottom: 15px;
  display: block;
  h1, h2, h3, h4, h5, h6 {
    display: block;
    margin-bottom: 0;
    color: $primary;
    text-transform: uppercase;
  }
  a {
    color: $dark-gray-medium;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: 0.9rem;
    text-decoration: none;
  }
}

.notification {
  ul {
    margin-bottom: 0;
    padding-left: 15px;
  }
}


#top-nav {
  .nav {
    background:$primary;
    color:#FFF;
    text-align: center;
    padding-top: 5px;
    padding-bottom:5px;
    font-size: 1.2rem;
    display: none;
    @include media-breakpoint-up(md) {
      display: block !important;
    }
  }
}

#main {
  .page-footer {
    display: flex;
    // margin-top: 20px;
    a {
      &:first-of-type {
        margin-right: 15px;
      }
    }
  }
}

.left_col {
  padding: 0;
  background-color: $gray-100;
  @include media-breakpoint-up(xl) {
    display: block !important;
  }
}


.body-nav {
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.card {
  padding: 15px;
  display: block;
}


.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.toggleable {
  &.hidden {
    display: none;
  }
}