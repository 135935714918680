.breadcrumb {
  background: transparent;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0;
  color: $secondary-light;
  width: auto; /*Chrome fix*/
  line-height: 15px; /*Chrome fix*/
  &[data-depth="1"] {
    display: none;
  }
  ol {
    padding-left: 0;
    margin-bottom: 0;
  }
  li {
    display: block;
    padding:7px 0px;
    float: left;
    &::after {
      content: ">";
      font-size: 0.8rem;
      margin: 0 3px;
    }
    &:last-child {
      color: $secondary-light;
      margin: 0;
      a {
        text-decoration: none;
      }
      &::after {
        content: "";
      }
    }
    a {
      color: $secondary-light;
      font-size: 0.8rem;
      text-decoration: none;
    }
  }
}
