input, select, textarea {
    // box-shadow: $input-shadow;
    transition: $transition-quick;
    border-radius: $border-radius;
    border: $input-border;
    color: $input-color;
}

.form-group {
    margin-bottom: 15px;
}

.form-control,
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    &:focus {
        border: $input-border-hover;
        border-radius: $border-radius;
        transition: $transition-quick;
        outline: 0 none;
        // box-shadow: $input-shadow;
    }
}

.form-maker-form {
    .form-group {
        margin-bottom: 15px;
    }

    .catcha-wrapper {
        margin-bottom: 15px;
        margin-right: 15px;
    }
    .captcha-container {
        margin-bottom: 5px;
    }
    .captcha {
        max-width: 304px;
    }
    .rgpd {
        font-size: 0.7rem;
    }
}

.custom-radio {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-top: 0.1rem;
    margin-right: 0.5rem;
    vertical-align: middle;
    cursor: pointer;
    background: $white;
    border: $dark-gray-medium 1px solid;
    border-radius: 50%;
    input[type="radio"] {
      cursor: pointer;
      opacity: 0;
  
      &:checked + span {
        position: absolute;
        top: 0.125rem;
        left: 0.125rem;
        display: block;
        width: 12px;
        height: 12px;
        background-color: $primary;
        border-radius: 50%;
      }
  
      &:focus + span {
        border-color: $primary;
      }
    }
  }