


.hideprices {
    button {
        border: 0;
        background: none;
        padding: 0;
        margin-left: 15px;
    }
    span {
        color: $white;
    }
}