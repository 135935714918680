.featured-products {

    margin-top: 15px;
    h3 {
        color: $primary;
        text-transform: capitalize;
        margin-bottom: 10px;
    }
    .all-product-link {
        font-size: 0.8rem;
        color: #b3b3b3;
        text-decoration: none;
        margin-left: 5px;
        display: inline-flex;
    }

}