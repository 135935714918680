#contact {
    .js-content-wrapper {
        .page {
            padding: 0;
        }
    }
    .contact-info {
        h2 {
            color: #515151;
        }
    }
}

.contact-nav {
    text-align: right;
}