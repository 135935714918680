.products-selection {
    padding: 15px 0;
    border-bottom: 1px solid $dark-gray-light;
    margin-bottom: 20px;
    font-size: 0.9rem;
    .dropdn {
        text-align: right;
        margin-left: auto;
        span {
            padding: 7px;
        }
        .dropdown {
            button {
                width: 100%;
                background-color: $white;
                border: 1px solid $dark-gray-light;
                padding: 7px;
                text-align: right;
            }
            div.show {
                display: flex;
                flex-direction: column;
                width: 94%;
                text-align: right;
                font-size: 0.9rem;

                a {
                    text-decoration: none;
                    padding: 7px;
                }
                a:hover {
                    background-color: $dark-gray-light;
                }
                button.show {
                    display: none;
                }
            }
        }
    }
    .total-products {
        padding-top: 7px;
    }
    div.showing {
        font-size: 0.8rem;
        color: $secondary-light;
    }
}